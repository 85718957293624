import React from 'react';

import { LinkedIn, YouTube } from '@mui/icons-material';
import { Avatar, Link, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Box, Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import resources from '../../resources/resources';
import linkAnimationsStyles from '../../styles/linkAnimations';
import pageContainerStyle from '../../styles/pageContainer';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          p: {
            xs: '50px 2rem',
            lg: '100px 5rem',
          },
        }}
      >
        <Grid2
          container
          spacing={4}
          direction={{
            lg: 'row',
            xs: 'column-reverse',
          }}
          sx={{
            ...pageContainerStyle,
            height: '100%',
          }}
          alignContent={{
            lg: 'center',
          }}
        >
          <Grid2 xs={12} lg={8}>
            <Stack
              spacing={{
                lg: 8,
                md: 6,
                xs: 4,
              }}
              sx={{
                height: '100%',
              }}
              alignItems={{
                xs: 'center',
                md: 'flex-start',
              }}
              textAlign={{
                xs: 'center',
                md: 'left',
              }}
            >
              <Box>
                <Typography
                  variant='h2'
                  fontSize={{
                    xs: '36px',
                    sm: '48px',
                    lg: '60px',
                  }}
                  py='0.5rem'
                  color='text.primary'
                >
                  {t('footer_title')}
                </Typography>
                <Link
                  href={'mailto:' + resources.CONTACT_EMAIL}
                  underline='none'
                  color='text.primary'
                >
                  <Typography
                    variant={'h5'}
                    fontSize={{
                      xs: '18px',
                      sm: '24px',
                      lg: '32px',
                    }}
                    fontWeight='300'
                    py='0.5rem'
                    color='text.primary'
                    sx={{ ...linkAnimationsStyles.underlineAnimation }}
                  >
                    {resources.CONTACT_EMAIL}
                  </Typography>
                </Link>
              </Box>

              <Grid2
                container
                rowSpacing={2}
                direction={{
                  sm: 'row',
                  xs: 'column',
                }}
              >
                <Grid2
                  pr={{
                    md: '2rem',
                  }}
                >
                  <Link underline='none' color='text.primary' href='/' variant='subtitle1'>
                    <Typography
                      variant='h6'
                      fontWeight='300'
                      sx={{
                        ...linkAnimationsStyles.underlineAnimation,
                      }}
                    >
                      {t('home')}
                    </Typography>
                  </Link>
                </Grid2>
                <Grid2
                  pr={{
                    md: '2rem',
                  }}
                >
                  <Link underline='none' color='text.primary' href='/About' variant='subtitle1'>
                    <Typography
                      variant='h6'
                      fontWeight='300'
                      sx={{
                        ...linkAnimationsStyles.underlineAnimation,
                      }}
                    >
                      {t('about')}
                    </Typography>
                  </Link>
                </Grid2>
                <Grid2
                  pr={{
                    md: '2rem',
                  }}
                >
                  <Link underline='none' color='text.primary' href='/Contact' variant='subtitle1'>
                    <Typography
                      variant='h6'
                      fontWeight='300'
                      sx={{
                        ...linkAnimationsStyles.underlineAnimation,
                      }}
                    >
                      {t('contact')}
                    </Typography>
                  </Link>
                </Grid2>
              </Grid2>

              <Grid2 container spacing={2}>
                <Grid2
                  pr={{
                    md: '1rem',
                  }}
                  color='text.primary'
                >
                  <Link
                    underline='none'
                    color='text.primary'
                    href={resources.YOUTUBE_LINK}
                    target='_blank'
                    rel='noopener'
                    variant='h6'
                    sx={{ ...linkAnimationsStyles.underlineAnimation }}
                  >
                    <YouTube fontSize='large'></YouTube>
                  </Link>
                </Grid2>
                <Grid2
                  pr={{
                    md: '1rem',
                  }}
                  color='text.primary'
                >
                  <Link
                    underline='none'
                    color='text.primary'
                    href={resources.LINKEDIN_LINK}
                    target='_blank'
                    rel='noopener'
                    variant='h6'
                    sx={{ ...linkAnimationsStyles.underlineAnimation }}
                  >
                    <LinkedIn fontSize='large'></LinkedIn>
                  </Link>
                </Grid2>
              </Grid2>
            </Stack>
          </Grid2>

          <Grid2 xs={12} lg={4} display={'flex'} justifyContent={'center'}>
            <Avatar
              src={resources.FOOTER_IMAGE_URL}
              sx={{
                height: {
                  lg: 300,
                  sm: 120,
                  xs: 80,
                },
                width: {
                  lg: 300,
                  sm: 120,
                  xs: 80,
                },
                '& img': {
                  transform: {
                    sm: 'scale(1.5) translateY(20px)',
                  },
                },
              }}
            ></Avatar>
          </Grid2>
        </Grid2>
      </Box>
    </footer>
  );
};

export default Footer;
