import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import resources from '../../resources/resources';
import pageContainerStyle from '../../styles/pageContainer';
import AboutHeader from '../misc/AboutHeader/AboutHeader';
import HeaderPictureText from '../misc/AboutPictureText/AboutPictureText';
import AboutText from '../misc/AboutText/AboutText';

const About = ({ onBgColorChange }) => {
  const aboutMeRef = useRef(null);

  const [aboutMeIsVisible, setAboutMeIsVisible] = useState(false);
  const [bgColor, setBgColor] = useState('black');

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    let aboutMeRefCurrent;

    const aboutMeIntersectionHandler = (entries) => {
      const entry = entries[0];
      setAboutMeIsVisible(entry.isIntersecting);
    };

    const aboutMeObserver = new IntersectionObserver(aboutMeIntersectionHandler, options);

    if (aboutMeRef.current) {
      aboutMeObserver.observe(aboutMeRef.current);
      aboutMeRefCurrent = aboutMeRef.current;
    }

    return () => {
      if (aboutMeRefCurrent) {
        aboutMeObserver.unobserve(aboutMeRefCurrent);
      }
    };
  }, []);

  useEffect(() => {
    if (aboutMeIsVisible) {
      setBgColor('white');
      onBgColorChange('white');
    } else {
      setBgColor('black');
      onBgColorChange('black');
    }
  }, [aboutMeIsVisible, onBgColorChange]);

  return (
    <React.Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 2 } }}
      >
        <Box
          sx={{
            backgroundColor: bgColor,
            color: aboutMeIsVisible ? 'text.secondary' : 'text.primary',
            display: 'flex',
            flexDirection: 'column',
            gap: '5rem',
            transition: 'background-color 1s ease, color 0.3s ease',
          }}
        >
          <Box
            sx={{
              p: {
                lg: '200px 5rem',
                xs: '80px 1rem',
              },
            }}
          >
            <AboutHeader></AboutHeader>
          </Box>

          <Box
            ref={aboutMeRef}
            sx={{
              ...pageContainerStyle,
              display: 'flex',
              flexDirection: 'column',
              gap: '5rem',
            }}
          >
            {/* About Me */}
            <HeaderPictureText
              imageUrl={resources.ABOUT_ME_IMAGE_URL}
              titleKey={'about_me_title'}
              textKey={'about_me'}
            ></HeaderPictureText>

            {/* Values */}
            <AboutText titleKey={'values_title'} textKey={'values'}></AboutText>

            {/* Project 1 */}
            <HeaderPictureText
              imageUrl={resources.ABOUT_EDITING_IMAGE_URL}
              titleKey={'editing_title'}
              textKey={'editing'}
              direction='right'
            ></HeaderPictureText>

            {/* Goals */}
            <AboutText titleKey={'goals_title'} textKey={'goals'}></AboutText>

            {/* Project 2 */}
            <HeaderPictureText
              imageUrl={resources.ABOUT_HOBBIES_IMAGE_URL}
              titleKey={'hobbies_title'}
              textKey={'hobbies'}
            ></HeaderPictureText>

            {/* Thanks */}
            <AboutText titleKey={'thanks_title'} textKey={'thanks'}></AboutText>
          </Box>
        </Box>
      </motion.div>
    </React.Fragment>
  );
};

export default About;
