import { Fragment, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import PlayerControls from '../PlayerControls/PlayerControls';
import './Player.css';

const Player = ({ videoUrl, settings, backgroundPlayer, centeredPlayIcon = false }) => {
  const playerRef = useRef(null);

  const [videoState, setVideoState] = useState({
    playing: settings?.playing ? true : false,
    muted: settings?.muted ? true : false,
    loop: settings?.loop ? true : false,
    volume: settings?.volume ?? 0,
    controls: settings?.controls ? true : false,
    config: settings?.config ?? {
      playerOptions: {
        color: '800020',
        background: true,
      },
    },
  });

  const { playing, muted, loop, volume, controls, config } = videoState;

  useEffect(() => {
    setVideoState((prevState) => {
      return {
        ...prevState,
        playing: settings?.playing,
        muted: settings?.muted,
        loop: settings?.loop,
        volume: settings?.volume,
        controls: settings?.controls,
        config: settings?.config,
      };
    });
  }, [
    settings?.config,
    settings?.controls,
    settings?.loop,
    settings?.muted,
    settings?.playing,
    settings?.volume,
  ]);

  return (
    <Fragment>
      <section className='player'>
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          width={'100%'}
          height={'100%'}
          playing={playing}
          muted={muted}
          loop={loop}
          volume={volume}
          controls={controls}
          config={config}
        ></ReactPlayer>
        {backgroundPlayer && <PlayerControls centeredPlayIcon={centeredPlayIcon} />}
      </section>
    </Fragment>
  );
};

export default Player;
