const resources = {
  BRAND_NAME: 'TOMMY LEONG',
  CONTACT_EMAIL: 'tommyleong1@gmail.com',
  YOUTUBE_LINK: 'https://www.youtube.com/@tommysmetalrectangles',
  LINKEDIN_LINK: 'https://www.linkedin.com/in/tommy-leong-23392b270/',
  HOME_VIDEO_URL: 'https://vimeo.com/855895523',
  PORTFOLIO_VIDEOS: [
    {
      title: 'Cycle 7 short build',
      videoURL: 'https://vimeo.com/932254401',
      thumbnailURL: '',
    },
    {
      title: 'There and back again',
      videoURL: 'https://vimeo.com/932253695',
      thumbnailURL: '',
    },
    {
      title: 'Pluto short build',
      videoURL: 'https://vimeo.com/932250325',
      thumbnailURL: '',
    },
    {
      title: 'Purple Bliss',
      videoURL: 'https://vimeo.com/932240755',
      thumbnailURL: '',
    },
    {
      title: 'FLX Virgo short build',
      videoURL: 'https://vimeo.com/858498873',
      thumbnailURL: '',
    },
    {
      title: 'Transitions',
      videoURL: 'https://vimeo.com/855895799',
      thumbnailURL: '',
    },
    {
      title: 'The silver lining of metal rectangles',
      videoURL: 'https://vimeo.com/858490976',
      thumbnailURL: '',
    },
    {
      title: 'The gift of Freedom',
      videoURL: 'https://vimeo.com/858493038',
      thumbnailURL: '',
    },
    {
      title: "Your fingers' personal trampoline",
      videoURL: 'https://vimeo.com/858495743',
      thumbnailURL: '',
    },
    {
      title: 'Bring class upon your desk',
      videoURL: 'https://vimeo.com/858497484',
      thumbnailURL: '',
    },
  ],
  ABOUT_HEADER_IMAGE_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_ABOUT_HEADER_IMAGE
      : 'https://firebasestorage.googleapis.com/v0/b/civil-orb-396116.appspot.com/o/20230814-DSCF0119.jpg?alt=media&token=32ab01c2-c407-4473-8b0e-052902ccda61',
  ABOUT_ME_IMAGE_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_ABOUT_ME_IMAGE
      : 'https://firebasestorage.googleapis.com/v0/b/civil-orb-396116.appspot.com/o/20230814-20230814-DSC03805.jpg?alt=media&token=cb1c20b2-97ed-4162-814e-1fea07754808',
  ABOUT_EDITING_IMAGE_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_ABOUT_EDITING_IMAGE
      : 'https://firebasestorage.googleapis.com/v0/b/civil-orb-396116.appspot.com/o/pexels-alex-fu-1767783.jpg?alt=media&token=b555ee71-b7c2-412e-a492-beb2d3fb1276',
  ABOUT_HOBBIES_IMAGE_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_ABOUT_HOBBIES_IMAGE
      : 'https://firebasestorage.googleapis.com/v0/b/civil-orb-396116.appspot.com/o/pexels-josh-hild-4424567.jpg?alt=media&token=8a20f7eb-9078-43d9-a026-4f44a6397e32',
  CONTACT_HEADER_IMAGE_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_CONTACT_HEADER_IMAGE
      : 'https://firebasestorage.googleapis.com/v0/b/civil-orb-396116.appspot.com/o/20220519-DSC01766.jpg?alt=media&token=c58328c2-9da7-4f8e-9afe-6aafeb5d0722',
  FOOTER_IMAGE_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_FOOTER_IMAGE
      : 'https://firebasestorage.googleapis.com/v0/b/civil-orb-396116.appspot.com/o/20230814-20230814-DSC03815.jpg?alt=media&token=3383ae8e-892c-47e7-82a8-c3a21f768598',
};

export default resources;
