import { Box, Typography } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Player from '../Player/Player';
import VideoDialog from '../VideoDialog/VideoDialog';
import './VideoCard.css';

const VideoCard = ({ videoCardData }) => {
  const styles = {
    video: {
      position: 'absolute',
      top: '0',
      left: '0',
      aspectRatio: '16/9',
      width: '100%',
      transition: 'all 0.5s ease',
    },
  };
  const titleRef = useRef(null);
  const videoCardRef = useRef(null);
  const videoBoxRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);

  const [videoDialogOpened, setVideoDialogOpened] = useState(false);

  const [videoCardVisible, setVideoCardVisible] = useState(false);

  const hoverHandler = () => {
    setIsHovered(!isHovered);
  };

  const videoDialogOpenHandler = () => {
    setVideoDialogOpened(true);
  };

  const videoDialogCloseHandler = () => {
    setVideoDialogOpened(false);
  };

  const setVisibility = (visibility) => {
    if (visibility) {
      titleRef.current.style.opacity = 1;
      videoBoxRef.current.style.opacity = 1;
    } else {
      titleRef.current.style.opacity = 0;
      videoBoxRef.current.style.opacity = 0;
    }
  };

  useEffect(() => {
    if (isMobile) {
      const options = {
        root: null,
        rootMargin: '-50%',
        threshold: 0,
      };

      let videoCardRefCurrent;

      const videoCardIntersectionHandler = (entries) => {
        const entry = entries[0];
        setVideoCardVisible(entry.isIntersecting);
      };

      const videoCardObserver = new IntersectionObserver(videoCardIntersectionHandler, options);

      if (videoCardRef.current) {
        videoCardObserver.observe(videoCardRef.current);
        videoCardRefCurrent = videoCardRef.current;
      }

      return () => {
        if (videoCardRefCurrent) {
          videoCardObserver.unobserve(videoCardRefCurrent);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (videoCardVisible) {
      setVisibility(true);
    } else if (isMobile && !videoCardVisible) {
      setVisibility(false);
    }
  }, [videoCardVisible]);

  useEffect(() => {
    if (isHovered) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  }, [isHovered]);

  return (
    <Fragment>
      <div
        ref={videoCardRef}
        className='bg-image-container'
        onMouseEnter={hoverHandler}
        onMouseLeave={hoverHandler}
        onClick={videoDialogOpenHandler}
      >
        <Box
          className='image-overlay'
          sx={
            isMobile
              ? {
                  opacity: videoCardVisible ? 1 : 0,
                }
              : {
                  '&:hover': {
                    opacity: 1,
                  },
                  '&:not(:hover)': {
                    opacity: 0,
                  },
                }
          }
        ></Box>
        <img src={videoCardData.thumbnailURL} alt='' className='image' loading='lazy' />
        <Box ref={videoBoxRef} id='video' sx={styles.video}>
          {(isHovered || (isMobile && videoCardVisible)) && (
            <Player
              videoUrl={videoCardData.videoURL}
              settings={{
                playing: !videoDialogOpened,
                muted: true,
                loop: true,
                controls: false,
                config: {
                  playerOptions: {
                    background: !videoDialogOpened,
                  },
                },
              }}
              backgroundPlayer
            ></Player>
          )}
        </Box>

        <Typography
          ref={titleRef}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            transition: 'all 0.3s ease',
            width: 'max-content',
            zIndex: 1,
          }}
          variant='h3'
          fontSize={{
            xl: '48px',
            lg: '32px',
            md: '24px',
            xs: '18px',
          }}
          color={'text.primary'}
        >
          {videoCardData.title}
        </Typography>
      </div>

      <VideoDialog
        open={videoDialogOpened}
        onClose={videoDialogCloseHandler}
        videoCardData={videoCardData}
      ></VideoDialog>
    </Fragment>
  );
};

export default VideoCard;
