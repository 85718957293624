import { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Home from './components/pages/Home';
import Layout from './layout/Layout';

function App() {
  const [bgColor, setBgColor] = useState('');

  const bgColorChangeHandler = (color) => {
    setBgColor(color);
  };

  return (
    <Router>
      <Layout bgColor={bgColor}>
        <Routes>
          <Route
            path={'/About'}
            element={<About onBgColorChange={bgColorChangeHandler}></About>}
          ></Route>
          <Route
            path={'/Contact'}
            element={<Contact onBgColorChange={bgColorChangeHandler}></Contact>}
          ></Route>
          <Route path={'/'} Component={Home}></Route>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
