import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { motion } from 'framer-motion';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import resources from '../../../resources/resources';
import './AboutHeader.css';

const AboutHeader = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box
        sx={{
          height: '100vh',
        }}
      >
        <Grid2
          container
          sx={{
            height: {
              lg: 'auto',
              xs: '100%',
            },
          }}
          display={'flex'}
          direction={{
            xl: 'row',
            xs: 'column-reverse',
          }}
          alignItems={{
            lg: 'flex-end',
            xs: 'center',
          }}
          alignContent={'center'}
          justifyContent={'center'}
          spacing={4}
        >
          <Grid2 lg={12} xl={6}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <motion.div
                initial={{
                  opacity: 0,
                  y: 100,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    type: 'spring',
                    duration: 2,
                  },
                }}
              >
                <Typography
                  variant='h1'
                  fontSize={'7vw'}
                  textAlign={{
                    xl: 'left',
                    xs: 'center',
                  }}
                >
                  {t('about_header_title')}
                </Typography>
              </motion.div>
            </Box>
          </Grid2>

          <Grid2 lg={12} xl={6}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <motion.div
                initial={{
                  opacity: 0,
                  y: 100,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    type: 'spring',
                    duration: 2,
                    delay: 0.25,
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flex: {
                      lg: 2,
                    },
                  }}
                >
                  <img src={resources.ABOUT_HEADER_IMAGE_URL} alt='' className='header-image' />
                </Box>
              </motion.div>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </Fragment>
  );
};

export default AboutHeader;
