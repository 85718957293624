// Import the functions you need from the SDKs you need
import { getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY || 'AIzaSyCq9eM_1rAkpTPq68HACBaUeyeT3F4jtz0',
  authDomain: process.env.REACT_APP_AUTH_DOMAIN || 'civil-orb-396116.firebaseapp.com',
  projectId: process.env.REACT_APP_PROJECT_ID || 'civil-orb-396116',
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET || 'civil-orb-396116.appspot.com',
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || '130223506512',
  appId: process.env.REACT_APP_APP_ID || '1:130223506512:web:bd53664ca01d6080b2fd55',
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || 'G-K84RPC4CZ0',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default firebaseConfig;
export const firestore = getFirestore();
export const storage = getStorage();
const analytics = isSupported().then((yes) => (yes ? getAnalytics(app) : null));
