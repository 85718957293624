import { Box } from '@mui/material';
import { Fragment, useState } from 'react';
import resources from '../../resources/resources';
import './Header.css';
import Player from './Player/Player';
import VideoDialog from './VideoDialog/VideoDialog';

const Header = () => {
  const [videoDialogOpened, setVideoDialogOpened] = useState(false);

  const headerVideoData = {
    title: '',
    videoURL: resources.HOME_VIDEO_URL,
    thumbnailURL: '',
  };

  const videoDialogOpenHandler = () => {
    setVideoDialogOpened(true);
  };

  const videoDialogCloseHandler = () => {
    setVideoDialogOpened(false);
  };

  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
          height: {
            md: '100vh',
            xs: '50vh',
          },
        }}
      >
        <article className='video-bg' onClick={videoDialogOpenHandler}>
          <Player
            videoUrl={resources.HOME_VIDEO_URL}
            settings={{
              playing: !videoDialogOpened,
              muted: true,
              loop: true,
              controls: false,
              config: {
                playerOptions: {
                  background: !videoDialogOpened,
                },
              },
            }}
            backgroundPlayer
            centeredPlayIcon={true}
          ></Player>

          <Box
            sx={{
              display: {
                md: 'block',
                xs: 'none',
              },
            }}
          >
            <div className='arrow-container'>
              <img
                width='50'
                height='50'
                src='https://img.icons8.com/ios/50/FFFFFF/expand-arrow--v2.png'
                alt='expand-arrow--v2'
              />
            </div>
          </Box>
        </article>
      </Box>

      <VideoDialog
        open={videoDialogOpened}
        onClose={videoDialogCloseHandler}
        videoCardData={headerVideoData}
      ></VideoDialog>
    </Fragment>
  );
};

export default Header;
