import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#800020',
    },
    text: {
      primary: grey[100],
      secondary: grey[900],
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    h1: {
      fontFamily: 'Cabin, sans-serif',
    },
    h2: {
      fontFamily: 'Cabin, sans-serif',
    },
    h3: {
      fontFamily: 'Cabin, sans-serif',
    },
  },
});

export default theme;
