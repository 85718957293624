import { Box, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { motion } from 'framer-motion';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const HeaderPictureText = ({ imageUrl, titleKey, textKey, direction = 'left' }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Grid2
        container
        spacing={4}
        direction={{
          lg: direction === 'left' ? 'row' : direction === 'right' ? 'row-reverse' : 'row',
          xs: 'column',
        }}
        disableEqualOverflow
        alignItems={'center'}
      >
        <Grid2 lg={6} xs={12}>
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <motion.div
              initial={{
                opacity: 0,
                x: direction === 'left' ? 50 : -50,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  type: 'spring',
                  duration: 2,
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  '& img': {
                    aspectRatio: '4/5',
                    objectFit: 'cover',
                    width: '75%',
                  },
                }}
              >
                <img src={imageUrl} alt='' loading='lazy' />
              </Box>
            </motion.div>
          </Box>
        </Grid2>

        <Grid2 lg={6} xs={12}>
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <motion.div
              initial={{
                opacity: 0,
                x: direction === 'left' ? -50 : 50,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  type: 'spring',
                  duration: 2,
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    width: '75%',
                  }}
                >
                  <Typography
                    variant='h3'
                    fontStyle={'italic'}
                    textAlign={{
                      lg: 'left',
                      xs: 'center',
                    }}
                  >
                    {t(titleKey)}
                  </Typography>

                  <Typography
                    variant='h5'
                    fontSize={{
                      md: '24px',
                      xs: '18px',
                    }}
                  >
                    {t(textKey)}
                  </Typography>
                </Stack>
              </Box>
            </motion.div>
          </Box>
        </Grid2>
      </Grid2>
    </Fragment>
  );
};

export default HeaderPictureText;
