const linkAnimationsStyles = {
  underlineAnimation: {
    display: 'inline-block',
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '0',
      bottom: '0',
      height: '1px',
      margin: '-2px 0',
      left: '0',
      backgroundColor: 'text.primary',
      transition: 'all 0.3s ease',
    },
    '&:hover:after': {
      width: '100%',
    },
  },
};

export default linkAnimationsStyles;
