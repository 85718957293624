import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import './PlayerControls.css';

const PlayerControls = (props) => {
  const theme = useTheme();
  const mdAndLower = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <section className='player-controls-container' ref={props.controlRef}>
      <div className='top-container'></div>

      <div className='mid-container'>
        {props.centeredPlayIcon && mdAndLower && (
          <div className='icon-btn'>
            <IconButton>
              <img
                width={'50'}
                height={'50'}
                src='https://img.icons8.com/ios/100/FFFFFF/play-button-circled--v1.png'
                alt='play-button-circled--v1'
              />
            </IconButton>
          </div>
        )}
      </div>

      <div className='bottom-container'>
        {(!props.centeredPlayIcon || !mdAndLower) && (
          <div className='icon-btn'>
            <IconButton>
              <img
                width={mdAndLower ? '25' : '100'}
                height={mdAndLower ? '25' : '100'}
                src='https://img.icons8.com/ios/100/FFFFFF/play-button-circled--v1.png'
                alt='play-button-circled--v1'
              />
            </IconButton>
          </div>
        )}
      </div>
    </section>
  );
};

export default PlayerControls;
