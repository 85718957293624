import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem('language') || 'fr',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        home: 'Portfolio',
        about: 'About',
        contact: 'Contact',
        portfolio_quote_1: 'Every frame tells a story.',
        portfolio_quote_2: 'Together, they create a masterpiece.',
        about_header_title: 'Welcome to my world of visual storytelling!',
        about_me_title: 'About Me',
        about_me:
          'I am Tommy, a passionate and creative video editor with a keen eye for detail and a knack for transforming raw footage into captivating narratives. With a background in Communication and new media, I bring a unique blend of technical expertise and artistic vision to every project I undertake.',
        values_title: 'Video editing is more than just a skill—',
        values:
          "It's a means of translating emotions, ideas, and messages into a visual language that resonates with audiences. Whether it's a heartfelt documentary, a dynamic promotional video, or a cinematic short film, I thrive on the challenge of enhancing the visual impact and flow of each frame.",
        editing_title: 'Editing',
        editing:
          "Proficient in industry-standard software such as Adobe Premiere Pro and Adobe After Effects, I am well-equipped to handle everything from basic edits to intricate post-production effects. My dedication to continuous learning means I'm always exploring new techniques and staying up-to-date with the latest trends in video editing.",
        goals_title: 'I am a firm believer in the power of collaboration and open communication.',
        goals:
          'My goal is to not only meet your expectations but to exceed them. I approach each project with an open mind and a commitment to understanding your vision, ensuring that the final product reflects your unique voice.',
        hobbies_title: 'Hobbies',
        hobbies:
          "When I'm not immersed in the world of timelines, you'll find me capturing my creative side through photography, as well as in the captivating realm of miniature painting, plastic modeling, and their creative customization. These diverse passions add a unique dimension to my work, providing an extra layer of creativity and innovation.",
        thanks_title:
          'Thank you for considering me as your partner in bringing your visual concepts to life.',
        thanks:
          "Let's collaborate and create something unforgettable. Feel free to explore my portfolio and reach out to discuss how we can work together to make your next project truly shine.",
        contact_me_email: 'Contact me at ',
        contact_me_form: 'Or fill out the form below',
        contact_form_name: 'Name',
        contact_form_email: 'Email',
        contact_form_message: 'Message',
        contact_form_send: 'Send',
        contact_form_send_success: 'Message sent!',
        contact_form_send_error:
          'Could not send the message. Please try again or send directly to the email on the page.',
        footer_title: "Let's craft moments that resonate.",
      },
    },
    fr: {
      translation: {
        home: 'Portfolio',
        about: 'À Propos',
        contact: 'Contact',
        portfolio_quote_1: 'Chaque image raconte une histoire.',
        portfolio_quote_2: "Ensemble, elles forment un chef-d'œuvre.",
        about_header_title: 'Bienvenue dans mon univers de récits visuels !',
        about_me_title: 'À Propos de Moi',
        about_me:
          "Je suis Tommy, un monteur vidéo passionné et créatif, doté d'un sens aigu du détail et d'un talent pour transformer des séquences brutes en récits captivants. Fort d'une formation en Communication et nouveaux médias, j'apporte un mélange unique d'expertise technique et de vision artistique à chaque projet que j'entreprends.",
        values_title: "Pour moi, le montage vidéo est bien plus qu'une compétence —",
        values:
          "C'est un moyen de traduire les émotions, les idées et les messages dans un langage visuel qui résonne auprès du public. Qu'il s'agisse d'un documentaire émouvant, d'une vidéo promotionnelle dynamique ou d'un court-métrage cinématographique, je prospère grâce au défi d'améliorer l'impact visuel et le flux de chaque image.",
        editing_title: 'Montage',
        editing:
          "Compétent dans les logiciels standard de l'industrie tels que Adobe Premiere Pro et Adobe After Effects, je suis bien équipé pour gérer tout, des montages de base aux effets de post-production complexes. Ma dédication à l'apprentissage continu signifie que j'explore toujours de nouvelles techniques et que je reste à jour avec les dernières tendances en matière de montage vidéo.",
        goals_title:
          'Je crois fermement au pouvoir de la collaboration et de la communication ouverte.',
        goals:
          "Mon objectif est de non seulement répondre à vos attentes, mais de les dépasser. J'aborde chaque projet avec un esprit ouvert et un engagement à comprendre votre vision, garantissant ainsi que le produit final reflète votre voix unique.",
        hobbies_title: 'Loisirs',
        hobbies:
          "Lorsque je ne suis pas immergé dans le monde des chronologies, vous me trouverez capturant mon côté créatif à travers la photographie, ainsi que dans l'univers captivant de la peinture de miniatures, de modèles plastiques et de leur personnalisation créative. Ces différentes passions apportent une dimension unique à mon travail, ajoutant une couche supplémentaire de créativité et d'innovation.",
        thanks_title:
          'Je vous remercie de me considérer comme votre partenaire pour donner vie à vos concepts visuels.',
        thanks:
          "Collaborons ensemble pour créer quelque chose d'inoubliable. N'hésitez pas à explorer mon portfolio et à me contacter pour discuter de la manière dont nous pouvons travailler ensemble pour faire briller votre prochain projet.",
        contact_me_email: 'Contactez-moi par courriel :',
        contact_me_form: 'Ou complétez le formulaire ci-dessous.',
        contact_form_name: 'Nom',
        contact_form_email: 'Courriel',
        contact_form_message: 'Message',
        contact_form_send: 'Envoyer',
        contact_form_send_success: 'Message envoyé!',
        contact_form_send_error:
          "Impossible d'envoyer le message. Veuillez réessayer ou envoyer un courriel à l'adresse ci-dessus.",
        footer_title: 'Créons ensemble des moments qui résonnent.',
      },
    },
  },
});

export default i18n;
