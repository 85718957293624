import { LoadingButton } from '@mui/lab';
import { Alert, Box, Link, Snackbar, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { motion } from 'framer-motion';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import resources from '../../resources/resources';
import { ContactFormService } from '../../services/contactForm.service';
import linkAnimationsStyles from '../../styles/linkAnimations';
import pageContainerStyle from '../../styles/pageContainer';

const Contact = ({ onBgColorChange }) => {
  const { t } = useTranslation();

  const [bgColor] = useState('white');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isSending, setIsSending] = useState(false);
  const [messageSentSnackbar, setMessageSentSnackbar] = useState(false);
  const [messageNotSentSnackbar, setMessageNotSentSnackbar] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSending(true);

    // Handle form submission here
    ContactFormService.sendContactForm(formData)
      .then(() => {
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        setMessageSentSnackbar(true);
      })
      .catch(() => {
        setMessageNotSentSnackbar(true);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessageSentSnackbar(false);
    setMessageNotSentSnackbar(false);
  };

  useEffect(() => {
    onBgColorChange(bgColor);
  }, [bgColor, onBgColorChange]);

  return (
    <Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 2 } }}
      >
        <Box
          sx={{
            ...pageContainerStyle,
            position: 'relative',
            p: {
              md: '200px 5rem',
              xs: '80px 1rem',
            },
          }}
        >
          <Grid2
            container
            disableEqualOverflow
            spacing={4}
            sx={{
              height: '100%',
            }}
            alignItems={'center'}
          >
            <Grid2 xl={6} xs={12} sx={{ height: '100%' }}>
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <motion.div
                  initial={{
                    opacity: 0,
                    y: 100,
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      type: 'spring',
                      duration: 2,
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      height: '100%',
                      display: 'inline-block',
                      '& img': {
                        maxWidth: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block',
                        aspectRatio: '4/3',
                      },
                    }}
                  >
                    <img src={resources.CONTACT_HEADER_IMAGE_URL} alt='' />

                    <Box
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                      }}
                    >
                      <Typography
                        variant='h2'
                        fontSize={{
                          xs: '18px',
                          sm: '32px',
                          lg: '60px',
                        }}
                        color={'text.primary'}
                        textAlign={'center'}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        {t('contact_me_email')}
                        <Link
                          href={'mailto:' + resources.CONTACT_EMAIL}
                          underline='none'
                          color={'text.primary'}
                          sx={{ ...linkAnimationsStyles.underlineAnimation }}
                        >
                          {resources.CONTACT_EMAIL}
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </motion.div>
              </Box>
            </Grid2>

            <Grid2 xl={6} xs={12}>
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <motion.div
                  initial={{
                    opacity: 0,
                    y: 100,
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      type: 'spring',
                      duration: 2,
                      delay: 0.25,
                    },
                  }}
                >
                  <Stack spacing={8}>
                    <Typography
                      variant='h2'
                      fontSize={{
                        xs: '18px',
                        sm: '32px',
                        lg: '60px',
                      }}
                      color={'text.secondary'}
                      textAlign={'center'}
                    >
                      {t('contact_me_form')}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                      <Stack
                        spacing={{
                          lg: 8,
                          xs: 4,
                        }}
                      >
                        <TextField
                          variant='standard'
                          type='text'
                          name='name'
                          required
                          value={formData.name}
                          onChange={handleChange}
                          sx={{
                            '& input': {
                              color: 'text.secondary',
                            },
                          }}
                          label={t('contact_form_name')}
                        ></TextField>
                        <TextField
                          variant='standard'
                          type='email'
                          name='email'
                          required
                          value={formData.email}
                          onChange={handleChange}
                          sx={{
                            '& input': {
                              color: 'text.secondary',
                            },
                          }}
                          label={t('contact_form_email')}
                        ></TextField>
                        <TextField
                          variant='standard'
                          type='text'
                          name='message'
                          required
                          multiline
                          value={formData.message}
                          onChange={handleChange}
                          sx={{
                            '& textarea': {
                              color: 'text.secondary',
                            },
                          }}
                          label={t('contact_form_message')}
                        ></TextField>
                        <LoadingButton
                          type='submit'
                          color='primary'
                          variant='contained'
                          loading={isSending}
                          sx={{ color: 'text.primary' }}
                        >
                          <span>{t('contact_form_send')}</span>
                        </LoadingButton>
                      </Stack>
                    </form>
                  </Stack>
                </motion.div>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </motion.div>

      <Snackbar
        open={messageSentSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleSnackbarClose}
      >
        <Alert severity='success' sx={{ width: '100%' }} onClose={handleSnackbarClose}>
          {t('contact_form_send_success')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={messageNotSentSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleSnackbarClose}
      >
        <Alert severity='error' sx={{ width: '100%' }} onClose={handleSnackbarClose}>
          {t('contact_form_send_error')}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Contact;
