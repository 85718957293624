import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Fragment } from 'react';
import VideoCard from '../VideoCard/VideoCard';

const VideoList = ({ videoListData }) => {
  return (
    <Fragment>
      <Grid2 container>
        {videoListData.map((videoCardData) => {
          return (
            <Fragment key={videoCardData.thumbnailURL}>
              <Grid2 lg={6} xs={12}>
                <VideoCard videoCardData={videoCardData}></VideoCard>
              </Grid2>
            </Fragment>
          );
        })}
      </Grid2>
    </Fragment>
  );
};

export default VideoList;
