import React from 'react';
import Footer from '../components/navigations/Footer';

import MenuBar from '../components/navigations/MenuBar';
import './Layout.css';

const Layout = (props) => {
  return (
    <React.Fragment>
      <MenuBar bgColor={props.bgColor}></MenuBar>

      <main>{props.children}</main>

      <Footer />
    </React.Fragment>
  );
};

export default Layout;
