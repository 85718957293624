import { addDoc, collection } from 'firebase/firestore';
import { firestore } from '../firebase';
import resources from '../resources/resources';

export class ContactFormService {
  static emailTo = resources.CONTACT_EMAIL;

  static async sendContactForm(formData) {
    try {
      // Send form data to Firebase Firestore
      const db = firestore;

      return await addDoc(collection(db, 'contactForms'), {
        to: this.emailTo,
        from: `${formData.name} <${formData.email}>`,
        message: {
          subject: ['Portfolio -', formData.name, `<${formData.email}>`].join(' '),
          text: formData.message,
        },
      });
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  }
}
