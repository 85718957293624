import React, { Fragment, useEffect, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import resources from '../../resources/resources';
import { StorageService } from '../../services/storage.service';
import Header from '../misc/Header';
import VideoList from '../misc/VideoList/VideoList';

const Home = () => {
  const { t } = useTranslation();
  const [videoListData, setVideoListData] = useState([]);

  useEffect(() => {
    async function getVideoListData() {
      const newImagesUrl = await StorageService.getAllUrls('videoList/images');
      const portfolioVideos = resources.PORTFOLIO_VIDEOS.map((videoData, index) => {
        return {
          ...videoData,
          thumbnailURL: newImagesUrl[index],
        };
      });

      if (!ignore && !_.isEqual(videoListData, portfolioVideos)) {
        setVideoListData(portfolioVideos);
      }
    }

    let ignore = false;
    getVideoListData();
    return () => {
      ignore = true;
    };
  }, [videoListData]);

  return (
    <Fragment>
      <Box sx={{ backgroundColor: 'black' }}>
        <Box>
          <Header></Header>
        </Box>

        <Stack spacing={2}>
          <Box
            sx={{
              p: {
                lg: '200px 5rem',
                xs: '80px 1rem',
              },
            }}
          >
            <Typography
              variant={'h2'}
              textAlign={'center'}
              color={'text.primary'}
              fontFamily={'Lato, sans-serif'}
              fontSize={{
                xs: '18px',
                sm: '32px',
                lg: '60px',
              }}
            >
              {t('portfolio_quote_1')}
            </Typography>
            <Typography
              variant={'h2'}
              textAlign={'center'}
              color={'text.primary'}
              fontFamily={'Lato, sans-serif'}
              fontSize={{
                xs: '18px',
                sm: '32px',
                lg: '60px',
              }}
            >
              {t('portfolio_quote_2')}
            </Typography>
          </Box>

          <Box>
            <VideoList videoListData={videoListData}></VideoList>
          </Box>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default Home;
