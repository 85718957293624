import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../firebase';

export class StorageService {
  static firebaseStorage = storage;

  static async getAllUrls(path) {
    const listRef = ref(this.firebaseStorage, path);
    const allRefs = await listAll(listRef);
    const allUrls = allRefs.items.map(async (item) => {
      return await getDownloadURL(item);
    });

    return Promise.all(allUrls);
  }
}
