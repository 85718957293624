import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const AboutText = ({ titleKey, textKey }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          gap: '2rem',
          py: '10rem',
          width: '65%',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <motion.div
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                type: 'spring',
                duration: 2,
              },
            }}
          >
            <Typography
              variant='h1'
              fontSize={{
                xs: '32px',
                md: '48px',
                lg: '60px',
              }}
              textAlign={'center'}
            >
              {t(titleKey)}
            </Typography>

            <Typography
              variant='h5'
              fontSize={{
                md: '24px',
                xs: '18px',
              }}
              textAlign={'center'}
            >
              {t(textKey)}
            </Typography>
          </motion.div>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AboutText;
