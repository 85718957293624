import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Fragment } from 'react';
import Player from '../Player/Player';

const VideoDialog = ({ open, onClose, videoCardData }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        open={open}
        maxWidth={'xl'}
        fullWidth
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            backgroundColor: 'black',
          },
        }}
      >
        <DialogTitle sx={{ m: videoCardData?.title ? '0' : '16px', p: 2 }}>
          {videoCardData?.title}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'text.primary',
          }}
        >
          <Close />
        </IconButton>
        <DialogContent
          sx={{
            p: 0,
          }}
        >
          <Box
            sx={{
              height: {
                xs: '100%',
                md: '80vh',
              },
            }}
          >
            <Player
              videoUrl={videoCardData?.videoURL}
              settings={{
                muted: true,
                loop: true,
                controls: true,
                config: {
                  playerOptions: {
                    color: '800020',
                  },
                },
              }}
            ></Player>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default VideoDialog;
