import {
  Box,
  CssBaseline,
  Link,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Divide as Hamburger } from 'hamburger-react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useLocation } from 'react-router-dom';
import './MenuBar.css';

import { LinkedIn, YouTube } from '@mui/icons-material';
import _ from 'lodash';
import resources from '../../resources/resources';
import { useLanguage } from '../../services/languageContext.service';
import linkAnimationsStyles from '../../styles/linkAnimations';

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    target: undefined,
  });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

function MenuBar(props) {
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const mdAndLower = useMediaQuery(theme.breakpoints.down('md'));
  const { currentLanguage, changeLanguage } = useLanguage();

  const [isOpen, setIsOpen] = React.useState(false);
  const [textColor, setTextColor] = React.useState('text.primary');

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const changeLanguageHandler = () => {
    changeLanguage();
  };

  React.useEffect(() => {
    if (_.isEqual(props.bgColor, 'black')) {
      setTextColor('text.primary');
    }

    if (_.isEqual(props.bgColor, 'white')) {
      setTextColor('text.secondary');
    }
  }, [props.bgColor, textColor]);

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          id='navbar'
          position='fixed'
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            px: '1rem',
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              justifyContent: 'space-between',
              minHeight: {
                lg: '96px',
                xs: '64px',
              },
            }}
          >
            <Box sx={{ flex: 1 }}></Box>

            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <Link
                underline='none'
                color={textColor}
                href='/'
                sx={{
                  ...linkAnimationsStyles.underlineAnimation,
                  display: 'block',
                  width: 'fit-content',
                  '&:after': {
                    ...linkAnimationsStyles.underlineAnimation['&:after'],
                    backgroundColor: textColor,
                  },
                }}
              >
                <Typography
                  noWrap
                  variant='h4'
                  fontSize={{
                    lg: '34px',
                    xs: '24px',
                  }}
                >
                  {resources.BRAND_NAME}
                </Typography>
              </Link>
            </Box>

            <Box
              sx={{
                flex: 1,
                display: {
                  lg: 'flex',
                  xs: 'none',
                },
                justifyContent: 'flex-end',
              }}
            >
              <Grid2 disableEqualOverflow container columnSpacing={4} className='links'>
                <Grid2>
                  <Link
                    underline='none'
                    color={textColor}
                    href='/'
                    variant='h6'
                    className={location.pathname === '/' ? 'active' : 'not-active'}
                    sx={{
                      ...linkAnimationsStyles.underlineAnimation,
                      '&:after': {
                        ...linkAnimationsStyles.underlineAnimation['&:after'],
                        backgroundColor: textColor,
                      },
                    }}
                  >
                    {t('home')}
                  </Link>
                </Grid2>
                <Grid2>
                  <Link
                    underline='none'
                    color={textColor}
                    href='/About'
                    variant='h6'
                    className={location.pathname === '/About' ? 'active' : 'not-active'}
                    sx={{
                      ...linkAnimationsStyles.underlineAnimation,
                      '&:after': {
                        ...linkAnimationsStyles.underlineAnimation['&:after'],
                        backgroundColor: textColor,
                      },
                    }}
                  >
                    {t('about')}
                  </Link>
                </Grid2>
                <Grid2>
                  <Link
                    underline='none'
                    color={textColor}
                    href='/Contact'
                    variant='h6'
                    className={location.pathname === '/Contact' ? 'active' : 'not-active'}
                    sx={{
                      ...linkAnimationsStyles.underlineAnimation,
                      '&:after': {
                        ...linkAnimationsStyles.underlineAnimation['&:after'],
                        backgroundColor: textColor,
                      },
                    }}
                  >
                    {t('contact')}
                  </Link>
                </Grid2>
                <Grid2>
                  <Link
                    component={'button'}
                    underline='none'
                    color={textColor}
                    variant='subtitle1'
                    className='not-active'
                    sx={{
                      ...linkAnimationsStyles.underlineAnimation,
                      textTransform: 'uppercase',
                      '&:after': {
                        ...linkAnimationsStyles.underlineAnimation['&:after'],
                        backgroundColor: textColor,
                      },
                    }}
                    onClick={changeLanguageHandler}
                  >
                    {currentLanguage === 'fr' ? 'en' : 'fr'}
                  </Link>
                </Grid2>
              </Grid2>
            </Box>

            <Box
              sx={{
                flex: 1,
                display: {
                  lg: 'none',
                  xs: 'flex',
                },
                justifyContent: 'flex-end',
                '& .hamburger-react': {
                  zIndex: 102,
                },
              }}
            >
              <Hamburger
                color={
                  textColor === 'text.primary'
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary
                }
                toggled={isOpen}
                onToggle={toggleDrawer}
              ></Hamburger>
            </Box>

            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              size={mdAndLower ? '100vw' : '250px'}
              lockBackgroundScroll={mdAndLower ? true : false}
              direction='right'
              style={{
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Stack
                sx={{
                  height: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack spacing={8} alignItems={'center'} sx={{ py: '5rem' }}>
                  <Link
                    underline='none'
                    color='text.primary'
                    href='/'
                    variant='subtitle1'
                    fontSize={'32px'}
                    className={location.pathname === '/' ? 'active' : 'not-active'}
                    sx={linkAnimationsStyles.underlineAnimation}
                  >
                    {t('home')}
                  </Link>

                  <Link
                    underline='none'
                    color='text.primary'
                    href='/About'
                    variant='subtitle1'
                    fontSize={'32px'}
                    className={location.pathname === '/About' ? 'active' : 'not-active'}
                    sx={linkAnimationsStyles.underlineAnimation}
                  >
                    {t('about')}
                  </Link>

                  <Link
                    underline='none'
                    color='text.primary'
                    href='/Contact'
                    variant='subtitle1'
                    fontSize={'32px'}
                    className={location.pathname === '/Contact' ? 'active' : 'not-active'}
                    sx={linkAnimationsStyles.underlineAnimation}
                  >
                    {t('contact')}
                  </Link>
                </Stack>

                <Stack alignItems={'center'}>
                  <Link
                    component={'button'}
                    underline='none'
                    color='text.primary'
                    variant='subtitle1'
                    className='not-active'
                    sx={{
                      ...linkAnimationsStyles.underlineAnimation,
                      textTransform: 'uppercase',
                      '&:after': {
                        ...linkAnimationsStyles.underlineAnimation['&:after'],
                        backgroundColor: textColor,
                      },
                    }}
                    onClick={changeLanguageHandler}
                  >
                    {currentLanguage === 'fr' ? 'en' : 'fr'}
                  </Link>
                  <Link
                    href={'mailto:' + resources.CONTACT_EMAIL}
                    underline='none'
                    color='text.primary'
                  >
                    <Typography
                      variant='subtitle1'
                      fontWeight='300'
                      py='0.5rem'
                      sx={linkAnimationsStyles.underlineAnimation}
                    >
                      {resources.CONTACT_EMAIL}
                    </Typography>
                  </Link>
                  <Grid2 disableEqualOverflow container spacing={2} sx={{ py: '1rem' }}>
                    <Grid2>
                      <Link
                        underline='none'
                        color='text.primary'
                        href={resources.YOUTUBE_LINK}
                        target='_blank'
                        rel='noopener'
                        variant='subtitle1'
                        sx={linkAnimationsStyles.underlineAnimation}
                      >
                        <YouTube></YouTube>
                      </Link>
                    </Grid2>
                    <Grid2>
                      <Link
                        underline='none'
                        color='text.primary'
                        href={resources.LINKEDIN_LINK}
                        target='_blank'
                        rel='noopener'
                        variant='subtitle1'
                        sx={linkAnimationsStyles.underlineAnimation}
                      >
                        <LinkedIn></LinkedIn>
                      </Link>
                    </Grid2>
                  </Grid2>
                </Stack>
              </Stack>
            </Drawer>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}

export default MenuBar;
